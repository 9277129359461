import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { Overlay } from "components/Overlay";
import { FormPublicApi } from "decl";
import {
  AddProductInstanceInput,
  DailyDiaryExtraConfig,
  DailyDiaryExtraConfigInput,
  EditProductInstanceInput,
  ItemStatusCollection,
  Product,
  ProductInstance,
  ProductSchema,
  ProductType,
} from "generated/graphql";
import {
  DataValidators,
  validateData,
  ValidatorType,
} from "helpers/validators";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ProductInstanceDailyDiaryExtraConfigForm } from "./ProductInstanceDailyDiaryExtraConfigForm";
import {
  defaultProductInstance,
  productInstanceToAddProductInstanceInput,
} from "./ProductInstanceForm.constants";
import { ProductTypename, ProductTypenameToProduct } from "constants/products";

export type ProductInstanceFormProps = {
  productInstance?: ProductInstance;
  products?: Product[];
  selectedProductLite?: Partial<Product>;
  selectedProductSchemas?: ProductSchema[];
  selectedProductStatusCollections?: ItemStatusCollection[];
  disabled?: boolean;
  apiRef?: React.Ref<FormPublicApi>;
  contractId?: string;
  onChange: (
    updatedProductInstance: AddProductInstanceInput | EditProductInstanceInput
  ) => void;
  onProductChange: (selectedProductId: string) => void;
};

export enum ProductInstanceMode {
  Solo = "solo",
  Multi = "multi",
}

export const productInstanceModes: Record<
  string,
  { id: ProductInstanceMode; label: string }
> = {
  [ProductInstanceMode.Multi]: {
    id: ProductInstanceMode.Multi,
    label: "Multi",
  },
  [ProductInstanceMode.Solo]: { id: ProductInstanceMode.Solo, label: "Solo" },
};

export const ProductInstanceForm: React.FC<ProductInstanceFormProps> = ({
  productInstance,
  products,
  selectedProductLite,
  selectedProductSchemas,
  selectedProductStatusCollections,
  disabled,
  apiRef,
  contractId,
  onChange,
  onProductChange,
}) => {
  const { t } = useTranslation();

  const dailyDiaryExtraConfigFormRef = useRef<FormPublicApi>(null);
  const productTypeRef = useRef<ProductType>();
  const [formData, setFormData] = useState<AddProductInstanceInput>(
    productInstance
      ? productInstanceToAddProductInstanceInput(productInstance)
      : defaultProductInstance
  );
  const [formDataErrors, setFormDataErrors] = useState<{
    [key: string]: string;
  }>({});

  const dataValidators: DataValidators = useMemo(
    () => ({
      description: {
        validators: [ValidatorType.Required],
      },
      numberingFormat: {
        validators: [ValidatorType.Required],
      },
      productId: {
        validators: [ValidatorType.Required],
      },
      ...(productTypeRef.current === ProductType.DailyDiary
        ? {}
        : {
            productSchemaId: {
              validators: [ValidatorType.Required],
            },
          }),
      statusCollectionId: {
        validators: [ValidatorType.Required],
      },
    }),
    []
  );

  const handleSelectedProductChange = (
    _: SyntheticEvent<Element, Event>,
    value: Product | null
  ) => {
    setFormData((curData) => {
      const crtProduct = products!.find((prod) => prod.id === value?.id);

      return {
        ...curData,
        productId: value?.id || "",
        description: curData.description || crtProduct?.name || "",
        numberingFormat:
          curData.numberingFormat || crtProduct?.numberingFormat || "",
      };
    });

    setFormDataErrors((curFormDataErrs) => {
      const { productId: _, ...rest } = curFormDataErrs;

      return rest;
    });

    onProductChange(value?.id || "");
  };

  const handleProductSchemaChange = (
    _: SyntheticEvent<Element, Event>,
    value: ProductSchema | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      productSchemaId: value?.id || "",
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { productSchemaId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleProductStatusCollectionChange = (
    _: SyntheticEvent<Element, Event>,
    value: ItemStatusCollection | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      statusCollectionId: value?.id || "",
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { statusCollectionId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleModeChange = (
    _: SyntheticEvent<Element, Event>,
    value: { id: ProductInstanceMode; label: string } | null
  ) => {
    setFormData((curData) => ({
      ...curData,
      soloModeSupported: value?.id === ProductInstanceMode.Solo,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { statusCollectionId: _, ...rest } = curFormDataErrs;

      return rest;
    });
  };

  const handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    setFormData((curData) => ({
      ...curData,
      [evt.target.name]: evt.target.value,
    }));

    setFormDataErrors((curFormDataErrs) => {
      const { [evt.target.name]: _, ...rest } = curFormDataErrs;

      if (evt.target.name === "value") {
        const { valueCurrency: __, ...remainingFields } = rest;
        return remainingFields;
      }

      return rest;
    });
  };

  const handleDailyDiaryExtraConfigChange = useCallback(
    (extraConfig: DailyDiaryExtraConfigInput | null) => {
      setFormData((curData) => {
        return extraConfig
          ? {
              ...curData,
              extraConfig: {
                dailyDiary: extraConfig,
              },
            }
          : {
              ...curData,
              extraConfig: null,
            };
      });
    },
    []
  );

  const renderExtraConfigForm = useCallback(() => {
    const crtProduct = products?.find(
      (product) => product.id === formData.productId
    );
    if (!crtProduct) {
      return null;
    }
    productTypeRef.current =
      ProductTypenameToProduct[
        (crtProduct as any).__typename as ProductTypename
      ];

    switch (productTypeRef.current) {
      case ProductType.DailyDiary:
        return (
          <ProductInstanceDailyDiaryExtraConfigForm
            extraConfig={productInstance?.extraConfig as DailyDiaryExtraConfig}
            onChange={handleDailyDiaryExtraConfigChange}
            apiRef={dailyDiaryExtraConfigFormRef}
          />
        );
      default:
        return null;
    }
  }, [formData, handleDailyDiaryExtraConfigChange, productInstance, products]);

  const validateForm = useCallback(
    (formData: AddProductInstanceInput | ProductInstance) => {
      const validationResult = validateData(formData, dataValidators);

      if (validationResult.valid) {
        setFormDataErrors({});
        if (productTypeRef.current === ProductType.DailyDiary) {
          return dailyDiaryExtraConfigFormRef.current!.validate();
        }
        return true;
      }
      setFormDataErrors(validationResult.errors);

      return false;
    },
    [dataValidators]
  );

  const resetForm = useCallback(() => {
    setFormData(
      productInstance
        ? productInstanceToAddProductInstanceInput(productInstance)
        : defaultProductInstance
    );
    if (productTypeRef.current === ProductType.DailyDiary) {
      dailyDiaryExtraConfigFormRef.current?.reset();
    }
  }, [productInstance]);

  useEffect(() => {
    const updatedFormData: AddProductInstanceInput | EditProductInstanceInput =
      {
        contractId: contractId!,
        description: formData.description,
        numberingFormat: formData.numberingFormat,
        productId: formData.productId,
        productSchemaId: formData.productSchemaId,
        soloModeSupported: formData.soloModeSupported,
        statusCollectionId: formData.statusCollectionId,
        id: productInstance?.id,
        extraConfig: formData.extraConfig,
      };

    onChange?.(updatedFormData);
  }, [formData, onChange, contractId, productInstance?.id]);

  useEffect(() => {
    setFormData(
      productInstance
        ? productInstanceToAddProductInstanceInput(productInstance)
        : defaultProductInstance
    );
  }, [productInstance]);

  useImperativeHandle(
    apiRef,
    () => ({
      validate: () => validateForm(formData),
      reset: resetForm,
    }),
    [validateForm, formData, resetForm]
  );

  return (
    <>
      {disabled && <Overlay withBackground />}
      <Grid container spacing={6}>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="product-select-label" shrink>
                {t("common.labels.product")}
              </InputLabel>
              <Autocomplete
                disablePortal
                id="product-autcomplete"
                options={products || []}
                sx={{ maxHeight: 200 }}
                value={
                  products?.find((prod) => prod.id === formData.productId) ||
                  null
                }
                onChange={handleSelectedProductChange}
                getOptionLabel={(product) => product.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("common.labels.product")}
                    required
                    error={!!formDataErrors.productId}
                    helperText={formDataErrors.productId}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="description"
            value={formData.description}
            onChange={handleTextFieldChange}
            type="text"
            label={t("AdminConsole.ProductInstances.labels.instance")}
            variant="standard"
            error={!!formDataErrors.description}
            helperText={formDataErrors.description}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="product-schema-select-label" shrink>
                {t("common.labels.config")}
              </InputLabel>
              <Autocomplete
                disablePortal
                id="product-schema-autcomplete"
                options={selectedProductSchemas || []}
                sx={{ maxHeight: 200 }}
                value={
                  selectedProductSchemas?.find(
                    (schema) => schema.id === formData.productSchemaId
                  ) || null
                }
                onChange={handleProductSchemaChange}
                getOptionLabel={(schema) => schema.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("common.labels.config")}
                    required
                    error={!!formDataErrors.productSchemaId}
                    helperText={formDataErrors.productSchemaId}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
            <>
              <InputLabel id="product-schema-select-label" shrink>
                {t("AdminConsole.ProductInstances.labels.itemStatuses")}
              </InputLabel>
              <Autocomplete
                disablePortal
                id="product-schema-collections-autcomplete"
                options={selectedProductStatusCollections || []}
                sx={{ maxHeight: 200 }}
                value={
                  selectedProductStatusCollections?.find(
                    (statusCollection) =>
                      statusCollection.id === formData.statusCollectionId
                  ) || null
                }
                onChange={handleProductStatusCollectionChange}
                getOptionLabel={(statusCollection) => statusCollection.name}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t(
                      "AdminConsole.ProductInstances.labels.itemStatuses"
                    )}
                    required
                    error={!!formDataErrors.statusCollectionId}
                    helperText={formDataErrors.statusCollectionId}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                )}
              />
            </>
          </FormControl>
        </Grid>
        {selectedProductLite?.soloModeSupported && (
          <Grid item md={6} xs={12}>
            <FormControl variant="standard" sx={{ minWidth: 120 }} fullWidth>
              <>
                <InputLabel id="mode-select-label" shrink>
                  {t("AdminConsole.ProductInstances.labels.mode")}
                </InputLabel>
                <Autocomplete
                  disablePortal
                  id="mode-autcomplete"
                  options={Object.values(productInstanceModes)}
                  sx={{ maxHeight: 200 }}
                  value={
                    formData.soloModeSupported
                      ? productInstanceModes[ProductInstanceMode.Solo]
                      : productInstanceModes[ProductInstanceMode.Multi]
                  }
                  onChange={handleModeChange}
                  getOptionLabel={(option) => option.label}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t("AdminConsole.ProductInstances.labels.mode")}
                      error={!!formDataErrors.soloModeSupported}
                      helperText={formDataErrors.soloModeSupported}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
              </>
            </FormControl>
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            name="numberingFormat"
            value={formData.numberingFormat}
            onChange={handleTextFieldChange}
            type="text"
            label={t("AdminConsole.ProductInstances.labels.numberingFormat")}
            variant="standard"
            error={!!formDataErrors.numberingFormat}
            helperText={formDataErrors.numberingFormat}
            InputLabelProps={{ shrink: true }}
            required
          />
        </Grid>
        {renderExtraConfigForm()}
      </Grid>
    </>
  );
};
